const firebaseConfig = {
  projectId: "mont-reve-bgttt",
  appId: "1:38449753064:web:5ab8b2ccfe4f3ad2d1d25d",
  databaseURL:
    "https://mont-reve-bgttt-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "mont-reve-bgttt.firebasestorage.app",
  apiKey: "AIzaSyAx6Q25-a9j--HC7u1SU002wC7Cuicv8_E",
  authDomain: "mont-reve-bgttt.firebaseapp.com",
  messagingSenderId: "38449753064",
};

export default firebaseConfig;
